<template>
  <div>
    <bread-crumb :crumbData="['品牌类型', '修改']" :chooseCrumbData="2" @close="onClose"></bread-crumb>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
      style="padding: 50px; width: 75%">
      <el-form-item label="品牌类型：" prop="column_name">
        <el-input v-model="ruleForm.column_name"></el-input>
      </el-form-item>
      <el-form-item label="缩略图：">
        <div>
          <el-upload class="avatar-uploader" action="#" :show-file-list="false" :http-request="uploadImg">
            <img v-if="ruleForm.thumbnail" :src="'http://192.168.3.3' + ruleForm.thumbnail" class="avatar" />
            <el-button size="small" type="primary" class="uploadBtu">选择图片</el-button>
          </el-upload>
        </div>
      </el-form-item>
      <el-row>
        <el-col :span="10">
          <el-form-item>
            <el-checkbox-group v-model="ruleForm.isEmpty">
              <el-checkbox label="启用空桶管理" name="isEmpty" :true-label="1" :false-label="0"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="适用范围：">
            <el-select v-model="ruleForm.range" placeholder="请选择" clearable>
              <el-option v-for="(item, index) in ranges" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="10">
          <el-form-item label="空桶押金：" style="width: 70%">
            <el-input v-model="ruleForm.deposit"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="空桶成本：" style="width: 70%">
            <el-input v-model="ruleForm.cost"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="排序：" prop="sort">
        <el-input v-model="ruleForm.sort"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: ["compile"],
  data() {
    return {
      token: "",

      // 表单数据
      imageUrl: "",
      ranges: ["商户", "服务商"],

      ruleForm: {
        column_name: "",
        thumbnail: "",
        isEmpty: 0,
        range: "",
        deposit: "",
        cost: "",
        sort: "",
      },
      rules: {
        column_name: [{ required: true, message: "必填字段", trigger: "blur" }],
        sort: [{ required: true, message: "必填字段", trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.echoList();
    this.token = localStorage.getItem("token");
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    // 数据回显
    echoList() {
      this.$http
        .post(
          "/Brand_Type/detail",
          this.$qs.stringify({
            id: this.compile,
          })
        )
        .then((res) => {
          if (res.data.thumbnail) {
            this.imageUrl = `http://txx.com${res.data.thumbnail}`;
          } else {
            this.imageUrl = false;
          }
          this.ruleForm = res.data;
        });
    },
    async uploadImg(file) {
      try {
        const base64 = await this.getBase64(file.file);
        const image = await this.$http.post("uploads_images/up", {
          file: base64,
        });
        this.imageUrl = image.data.path;
        this.ruleForm.thumbnail = image.data.path;
      } catch (error) {
        console.log(error);
      }
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },

    // 修改表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post(
              "/Brand_Type/add",
              ({
                id: this.compile,
                ...this.ruleForm,
              })
            )
            .then((res) => {
              this.$message({
                type: res.data.status,
                message: res.data.msg,
              });
              if (res.data.status == "success") {
                this.onClose(0);
              }
            });
        } else {
          this.$message.error("请确保必填选项已填！");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  margin: 10px 0;
  border: 1px solid #dddddd;
}
</style>
